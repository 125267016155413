import { useQuery } from '@tanstack/react-query';
import type {
  ProductCardProduct,
  ProductCardProps,
} from '@we-make-websites/ui-lib';
import { localeToCountryLanguage } from '@/components/route/utils/localeToCountryLanguage';
import { useGetCollectionProductsQuery } from '@/lib/shopify-storefront/__generated__/GetCollectionProducts';
import type {
  GetCollectionProductsQueryVariables,
  GetCollectionProductsQuery,
} from '@/lib/shopify-storefront/__generated__/GetCollectionProducts';
import { storefrontQueryDataSource } from '@/lib/shopify-storefront/dataSources';
import type { StoreLocale } from '@/root/constants';
import { formatProductForProductCard } from '@/utils/format/shopify/formatProduct';
// import { formatProductForProductCard } from '@/utils/format/shopify/formatProduct';

/**
 * A hook for querying a collection and get products
 * to use with Product Cards.
 * @param collection - The Collection to query.
 * @param locale - The store locale.
 * @returns {Object}
 */
export const useCollectionProductsQuery = (
  collection: { handle: string },
  proCustomer: boolean,
  locale: StoreLocale
): {
  products: ProductCardProps[];
  isLoading: boolean;
} => {
  const localeCodes = localeToCountryLanguage(locale);
  const tagFilter = proCustomer ? 'pro' : 'consumer';

  /**
   * Query Product data from Shopify
   *
   * Setting product query variables then performing the query
   */
  const getCollectionProductsQueryVariables: GetCollectionProductsQueryVariables =
    {
      handle: collection.handle,
      tagFilter,
      includeReferenceMetafieldDetails: true,
      ...localeCodes,
    };

  const { data = [], isLoading } = useQuery(
    useGetCollectionProductsQuery.getKey(getCollectionProductsQueryVariables),
    useGetCollectionProductsQuery.fetcher(
      storefrontQueryDataSource(locale),
      getCollectionProductsQueryVariables
    ),
    {
      select: formatCollectionProductsQuery,
    }
  );

  const productsRaw: ProductCardProps[] = data
    ?.filter((response) => response)
    .map((product) => ({ product, isLoading }));

  return {
    products: productsRaw,
    isLoading,
  };
};

const formatCollectionProductsQuery = (
  originalData: GetCollectionProductsQuery
): (ProductCardProduct | undefined)[] => {
  const products: (ProductCardProduct | undefined)[] = [];
  const data = originalData.collection?.products.nodes;

  data?.forEach((productData) => {
    const product = formatProductForProductCard({ product: productData });
    products.push(product);
  });

  return products;
};
