import type { StoreMediaImage } from '@/components/productContext/types/storeMedia';
import { StoreMediaType } from '@/components/productContext/types/storeMedia';
import type { GetProductQuery } from '@/lib/shopify-storefront/__generated__/GetProduct';

/**
 * Handle transformations of product media into a more usable shape.
 */
export function getProductImages(
  product: GetProductQuery['product']
): StoreMediaImage[] {
  if (!product?.media) {
    return [];
  }

  const media: StoreMediaImage[] = [];

  product.media.edges.forEach((edge) => {
    const { node } = edge;

    if (node.__typename !== 'MediaImage') return;

    media.push({
      type: StoreMediaType.Image,
      id: node.image?.id ?? '',
      image: {
        id: node.image?.id ?? '',
        url: node.image?.url ?? '',
        altText: node.image?.altText ?? '',
        width: node.image?.width ?? 0,
        height: node.image?.height ?? 0,
      },
    });
  });

  return media;
}
