import { useQuery } from '@tanstack/react-query';
import { RecommendationsManual } from '@we-make-websites/ui-lib/src';
import type {
  RecommendationsManualProps,
  ProductCardProduct,
} from '@we-make-websites/ui-lib/src';
import { useRouter } from 'next/router';
import { useContext } from 'use-context-selector';
import { CurrencyCode } from '@/lib/shopify-storefront/__generated__/types';
import type { StoreLocale } from '@/root/constants';
import { isChLocale, storeLocale } from '@/root/constants';
import { getCookie } from '@/utils/helpers';
import { useProductCardQuery } from '@/utils/hooks/useProductCardQuery';
import { CartContext } from '../cartContext/CartContext';
import { CustomerContext } from '../customerContext/CustomerContext';
import { ProductCardConnected } from '../productCard/ProductCardConnected';
import type {
  IncreasinglyAPIBundle,
  IncreasinglyAPIRequestPayload,
  IncreasinglyAPIResponse,
} from './IncreasinglyAPITypes';
import type {
  IncreasinglyAddToCartTrackingEvent,
  IncreasinglyClickTrackingEvent,
  IncreasinglyTrackingPayload,
} from './IncreasingTrackingTypes';

const getIncreasinglyApiKey = (proCustomer: boolean, locale: string) => {
  const parsedLocale = storeLocale(locale);

  if (isChLocale(parsedLocale)) {
    return 'o9I09ch';
  }
  if (proCustomer) {
    return 'o9I0TrauK';
  }
  return 'o9I0uK';
};

type FormattedIncreasinglyItem = {
  product: ProductCardProduct;
  bundle: IncreasinglyAPIBundle;
  bundleProductId: string;
};

type IncreasinglyApiSectionProps = Omit<
  RecommendationsManualProps,
  'products'
> & { type: 'catalog_product_view' | 'checkout_cart_index' };

export const IncreasinglyApiSection = (props: IncreasinglyApiSectionProps) => {
  const router = useRouter();
  const locale = storeLocale(router.locale || router.defaultLocale);

  const cartContext = useContext(CartContext);
  const { cart, cartQuickAdd } = cartContext;

  const customerContext = useContext(CustomerContext);
  const { proCustomer } = customerContext;

  const cartCurrency =
    cart?.estimatedCost?.totalAmount?.currencyCode ?? CurrencyCode['Gbp'];

  const cartLines = cart?.lines ?? [];

  const cartProductSKUs = cartLines.map((line) => line.merchandise.sku);

  const apiKey = getIncreasinglyApiKey(proCustomer, locale);

  const increasinglyRawPayload: IncreasinglyAPIRequestPayload = {
    product_ids:
      props.type === 'checkout_cart_index'
        ? cartProductSKUs.join(',')
        : cartProductSKUs[0],
    api_key: apiKey,
    fr: '1',
    page_type: props.type,
    client_visitor_id: getCookie('ivid'),
    ...(props.type === 'checkout_cart_index' && { client_id: '' }), // not applicable
    ...(props.type === 'checkout_cart_index' && { category_id: '' }), // not applicable
    ...(props.type === 'catalog_product_view' && { is_tc: '1' }),
    ...(props.type === 'catalog_product_view' && { no_of_bundles: '10' }),
  };

  const formattedPayload = objectToString(increasinglyRawPayload);

  const encodedPayload = base64Encode(formattedPayload);

  const { data: increasinglyData } = useQuery<IncreasinglyAPIResponse>({
    queryKey: ['IncreasinglyApiCall', encodedPayload],
    queryFn: async () => {
      const response = await fetch(
        `https://rapidload.increasingly.co/increasingly_bundles?irb/${encodedPayload}`
      );

      if (!response.ok) {
        throw new Error('Error fetching Increasingly API data');
      }

      return response.json();
    },
  });

  const formattedIncreasinglyData = increasinglyData
    ? formatIncreasinglyApiData(increasinglyData, cartProductSKUs, cartCurrency)
    : [];

  const productsArray = formattedIncreasinglyData;
  const handlesArray = productsArray.map((data) => ({
    handle: data.product.handle,
  }));

  const { products, isLoading } = useProductCardQuery(handlesArray, locale);

  /**
   * Custom Add to Cart handler
   * - Triggers standard `cartQuickAdd` function used by `ProductCardConnected`
   * - Also triggers add to cart tracking to send data to Increasingly
   */
  const addToCart = ({ id }: { id: string }) => {
    cartQuickAdd({ productId: id });
    trackAddToCart(id);
  };

  /**
   * Additional on click callback
   * - Triggers click tracking to send data to Increasingly
   */
  const additionalOnClick = (
    _event: React.MouseEvent<Element, MouseEvent>,
    product: ProductCardProduct
  ) => {
    trackProductClick(product);
  };

  /**
   * Track add to cart event for Increasingly reporting
   * - Takes the Product ID from the Add to Cart event and finds the matching
   *   bundle data.
   * - Bundle data is formatted and then added to then posted to Increasingly
   */
  const trackAddToCart = async (id: string) => {
    const legacyId = id.split('/').pop() ?? '';

    const matchingData = getBundleDataByProductId(
      legacyId,
      formattedIncreasinglyData
    );

    console.log(matchingData);

    const rawEventData: IncreasinglyAddToCartTrackingEvent = {
      event_data: {
        bundle_data: {
          id: matchingData?.bundle?.BundleId ?? 0,
          product_ids: matchingData?.bundle?.ProductIds ?? [],
          product_id: matchingData?.bundleProductId ?? '',
        },
      },
      event_type: 'bundle_add_to_cart',
      page_type: props.type === 'catalog_product_view' ? '107' : '103',
      is_logged: '0',
      method: 'track',
      platform: '',
      token: apiKey,
    };

    console.log(rawEventData);

    await sendIncreasinglyTrackingData(rawEventData, locale);
  };

  /**
   * Track product click events
   * - Get the matching bundle data
   * - Format the payload and send to Increasingly
   */
  const trackProductClick = async (product: ProductCardProduct) => {
    const legacyId = product.id.split('/').pop() ?? '';

    const matchingData = getBundleDataByProductId(
      legacyId,
      formattedIncreasinglyData
    );

    if (!matchingData) return;

    console.log(matchingData);

    const bundleProductId = matchingData.bundleProductId;
    const coreProductId =
      matchingData.bundle?.ProductIds?.find(
        (id: string) => id !== bundleProductId
      ) ?? '';

    const rawEventData: IncreasinglyClickTrackingEvent = {
      event_data: {
        product_id: bundleProductId,
        core_product_id: coreProductId,
      },
      event_type: 'bundle_product_click_tracking',
      // 107 - PDP/minicart sidebar; 103 - cart page
      page_type: props.type === 'catalog_product_view' ? '107' : '103',
      is_logged: '0',
      method: 'track',
      platform: '',
      token: apiKey,
    };

    console.log(rawEventData);

    await sendIncreasinglyTrackingData(rawEventData, locale);
  };

  const productsWithTracking = products.map((product) => {
    return {
      ...product,
      addToCart,
      additionalOnClick,
    };
  });

  return (
    <RecommendationsManual
      {...props}
      productCardComponent={ProductCardConnected}
      products={productsWithTracking}
      isLoading={isLoading}
    />
  );
};

/**
 * Format the raw Increasingly data
 * - Loops through each bundle from the API and returns an array of bundles that
 *   have products that aren't yet in the user's cart
 */
const formatIncreasinglyApiData = (
  data: IncreasinglyAPIResponse,
  /**
   * Array of product ids that are already in the user's cart.
   */
  cartProductIds: string[] = [],
  currencyCode: CurrencyCode
): FormattedIncreasinglyItem[] => {
  const validBundles: Array<{
    productId: string;
    bundle: IncreasinglyAPIBundle;
  }> = [];

  /**
   * Loop through all the bundle data to find valid bundles to show.
   * - Bundles are only added if the bundle contains products that aren't in the
   *   cart
   */
  if (data.Bundles && Array.isArray(data.Bundles)) {
    data.Bundles.forEach((bundle) => {
      const bundleProductIdNotInCart = bundle.ProductIds?.find((id) => {
        return !cartProductIds.includes(id);
      });

      /**
       * If all products in the bundle are already in the cart, early return.
       */
      if (!bundleProductIdNotInCart) return;

      validBundles.push({
        productId: bundleProductIdNotInCart,
        bundle,
      });
    });
  }

  const formattedBundles: FormattedIncreasinglyItem[] = [];

  validBundles.forEach((bundleData) => {
    const { productId, bundle } = bundleData;

    /**
     * For the passed product ID, get the matching product data from the
     * `ProductsDetail` array
     */
    const matchingProductData = data?.ProductsDetail?.find(
      (product) => product?.ProductId === productId
    );

    if (!matchingProductData) return;

    const handle =
      matchingProductData?.ProductUrl?.split('/').pop()?.split('.')[0] ?? '';

    const formattedData: ProductCardProduct = {
      id: matchingProductData?.ProductSku,
      title: matchingProductData?.ProductName ?? '',
      image: matchingProductData?.ImageURL ?? '',
      handle,
      price: {
        amount: matchingProductData.Price
          ? Number(matchingProductData.Price)
          : 0,
        currencyCode,
      },
      systemName: '', // Missing from data.
      url: matchingProductData?.ProductUrl ?? '',
      availableForSale: true,
    };

    formattedBundles.push({
      product: formattedData,
      bundle,
      bundleProductId: productId,
    });
  });

  return formattedBundles;
};

/**
 * Find relevant Increasingly data based on product id
 */
const getBundleDataByProductId = (
  /**
   * Product ID string
   * - Legacy ID format (not `gid://` format)
   */
  id: string,
  /**
   * Formatted increasingly bundle data from initial API call.
   */
  increasinglyData: FormattedIncreasinglyItem[]
) => {
  return increasinglyData.find((data) => {
    return data?.product?.id === id;
  });
};

/**
 * Send tracking event ot Increasingly API endpoint
 * - Event object is stringified and base64 encoded
 * - Additional information for `uri` and `vid` are added to the payload
 * - Data is sent to Increasingly endpoint.
 */
const sendIncreasinglyTrackingData = async (
  eventData:
    | IncreasinglyAddToCartTrackingEvent
    | IncreasinglyClickTrackingEvent,
  locale: StoreLocale
) => {
  const eventDataString = JSON.stringify(eventData);
  const encodedEventData = base64Encode(eventDataString);

  const payload: IncreasinglyTrackingPayload = {
    eventData: encodedEventData,
    uri: `${window.location.origin}/${locale}/cart`,
    vid: getCookie('ivid') ?? '',
  };

  const response = await fetch(
    'https://optimizedby.increasingly.co/ImportData',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }
  );

  if (!response || !response?.ok) {
    console.log('Failed to record add to cart click');
  }

  return await response.json();
};

/**
 * Convert object to an encoded-like format.
 */
const objectToString = (object: object) => {
  return Object.entries(object)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');
};

/**
 * Convert a string into base64 encoding
 */
const base64Encode = (dataString: string) => {
  const buff = Buffer.from(dataString, 'utf8');
  return buff.toString('base64');
};
