import type {
  ProductCardProps,
  RecommendationsManualProps,
} from '@we-make-websites/ui-lib';
import { RecommendationsManual } from '@we-make-websites/ui-lib';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import {
  Configure,
  Index,
  InstantSearch,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import { useContext } from 'use-context-selector';
import { CustomerContext } from '@/components/customerContext/CustomerContext';
import { storeLocale } from '@/root/constants';
import { algoliaProductIndex } from '@/utils/algoliaConstants';
import { formatAlgoliaProductHit } from '@/utils/format/algoliaHit';
import type { AlgoliaProductHitParams } from '@/utils/format/algoliaHit';
import { useCollectionProductsQuery } from '@/utils/hooks/useCollectionProductsQuery';
import { searchClient } from '@/utils/search-client';
import { FeatureFlagsContext } from '../featureFlagsProvider/FeatureFlagsContext';
import { IncreasinglyApiSection } from '../increasinglySection/IncreasinglyAPISection';
import { ProductCardConnected } from '../productCard/ProductCardConnected';
import { generateProTagFilter } from '../shelf/Shelf';

export interface ProductRecommendationsProps
  extends Omit<RecommendationsManualProps, 'products'> {
  collection: {
    handle: string;
  };
  filters: string;
  isLoading?: boolean;
  ruleContext: string;
  proCustomer?: boolean;
  locale?:
    | 'en-US'
    | 'en-GB'
    | 'en-CH'
    | 'fr-CH'
    | 'de-CH'
    | 'it-CH'
    | 'fr-FR'
    | 'es-ES'
    | 'de-DE'
    | 'it-IT';
  // | 'jp-JP';
  recommendationsType?: 'manual' | 'increasingly';
  type?: 'catalog_product_view' | 'checkout_cart_index';
  asIndex?: boolean;
}

type CTA = {
  title: string;
  url: string;
  newTab: boolean;
};
interface ComponentData {
  title: string;
  description?: string;
  descriptionLink?: CTA;
  cta?: CTA;
  products: Array<ProductCardProps>;
  backgroundColor?: boolean;
  viewModeOnlyMobile?: boolean;
  proCustomer?: boolean;
  placement?: 'blog';
  isLoading?: boolean;
}

export function ProductRecommendations(props: ProductRecommendationsProps) {
  const { filters, ruleContext, recommendationsType } = props;
  const { proCustomer } = useContext(CustomerContext);

  const featureFlagsContext = useContext(FeatureFlagsContext);

  if (featureFlagsContext === undefined)
    throw new Error(
      'ProductRecommendations must be used within a FeatureFlagsContext'
    );

  const router = useRouter();
  const locale = storeLocale(router.locale || router.defaultLocale);
  const additionalProps = {
    proCustomer,
    locale,
  };
  const { isProProductFilteringEnabled } =
    featureFlagsContext.featureFlags[locale];

  if (
    recommendationsType === 'increasingly' && [
      featureFlagsContext.featureFlags[locale].increasingly,
    ]
  ) {
    return (
      <IncreasinglyApiSection
        type={props.type ?? 'checkout_cart_index'}
        {...props}
      />
    );
  }

  if (!filters && !ruleContext) {
    return (
      <RecommendationCollectionComponent {...props} {...additionalProps} />
    );
  }

  const baseFilters = generateProTagFilter(
    isProProductFilteringEnabled,
    proCustomer,
    ''
  );

  const fullBaseFilters = filters
    ? `${baseFilters} AND ${filters}`
    : baseFilters;

  const ruleContexts = ruleContext
    ? ruleContext.split(',').map((rule) => rule.trim())
    : [];

  const configureWithHits = (
    <>
      <Configure
        filters={fullBaseFilters}
        hitsPerPage={8}
        ruleContexts={['recommendations', ...ruleContexts]}
        distinct
      />
      <RecommendationSearchComponent {...props} {...additionalProps} />
    </>
  );

  if (props?.asIndex) {
    return (
      <Index
        indexName={algoliaProductIndex(locale)}
        indexId={`recommendations`}
      >
        {configureWithHits}
      </Index>
    );
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={algoliaProductIndex(locale)}
    >
      {configureWithHits}
    </InstantSearch>
  );
}

/**
 * Create a recommendation search component for receiving the Algolia Instant
 * search context and updating the componentData products as applicable.
 */
const RecommendationSearchComponent = (props: ProductRecommendationsProps) => {
  const { results, status, error } = useInstantSearch({ catchError: true });
  const { collection, locale, isLoading } = props;
  let algoliaProducts: ProductCardProps[] = [];

  if (!error) {
    algoliaProducts = results?.hits.length
      ? results.hits.map((result) => {
          return {
            product: formatAlgoliaProductHit({
              hit: result,
              collectionHandle: collection.handle,
              locale,
            } as AlgoliaProductHitParams),
            isLoading: status !== 'idle',
          };
        })
      : [];
  }

  // Memoize componentData.
  const componentData: ComponentData = useMemo(() => {
    return {
      ...props,
      products: algoliaProducts,
      isLoading,
    };
  }, [algoliaProducts, isLoading, props]);

  return (
    <RecommendationsManual
      {...componentData}
      productCardComponent={ProductCardConnected}
    />
  );
};

/**
 * This component can be removed after filter and ruleContexts have been
 * merchandised in Contentful.
 */
const RecommendationCollectionComponent = (
  props: ProductRecommendationsProps
) => {
  const { collection, proCustomer, locale } = props;
  const { products, isLoading } = useCollectionProductsQuery(
    collection,
    proCustomer || false,
    locale || 'en-GB'
  );

  const productsMapped = products
    .map((product) => {
      if (product && product.product && product.product.id) {
        const newProduct: ProductCardProps = { ...product };

        if (product?.product?.id?.split('/').length > 2 && newProduct.product) {
          newProduct.product.id = product?.product?.id?.split('/').pop() || '';
        }

        return newProduct;
      }
    })
    .filter((product): product is ProductCardProps => {
      return !!product && !!product.product;
    });

  // Memoize componentData.
  const componentData: ComponentData = useMemo(() => {
    console.log('productsMapped: ', productsMapped);
    return {
      ...props,
      products: productsMapped,
      isLoading,
    };
  }, [productsMapped, isLoading, props]);

  return (
    <RecommendationsManual
      {...componentData}
      productCardComponent={ProductCardConnected}
    />
  );
};
